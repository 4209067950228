import React, {Component} from 'react'
import {connect} from 'react-redux'
import {BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import {authenticateUsingToken, redirectToLoginAction} from '../actions/authActions'
import CartPage from './Pages/CartPage'
import PrivateRoute from './PrivateRoute'
import PackList from './Pages/PackList'
import LoginPage from './Pages/LoginPage'
import UserPage from './Pages/UserPage'
import CustomerAcceptAccessPage from './Pages/Customer/CustomerAcceptAccessPage'
import ConditionalRoute from "./ConditionalRoute";
import { useTranslation } from "react-i18next";

import {ReactComponent as LogoSvg} from './assets/logo.svg';

import './App.scss'
//
const NotFoundPage = () => {
    return <div className="not-found-entry-page-message">
        <div className="logo-container">
            <LogoSvg/>
        </div>
        <h2>You have to login using the following url in order to proceed</h2>
        <div className="login-link">
            <Link to="/login">
                Login
            </Link>
        </div>
    </div>
}

const LanguageSwitch = () => {
    const { i18n } = useTranslation();


    const handleLanguageChange = (language) => {
        console.log(i18n)
        i18n.changeLanguage(language);
    };

    return <div className="language-footer">
        <p>
            <span className={ i18n.language === 'el' ? 'selected-language' : ''  } onClick={ () => handleLanguageChange('el') } >GR</span> /
            <span className={ i18n.language === 'en' ? 'selected-language' : ''  } onClick={ () => handleLanguageChange('en') } >EN</span>
        </p>
    </div>
}

class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {auth: {authenticated, redirectToLogin, missingToken, triedToAuth}, authenticateUsingToken} = this.props

        return <div className="application-container">
            <Router>
                <Switch>
                    <PrivateRoute
                        authenticated={authenticated}
                        path="/app"
                        component={UserPage} authenticateUsingToken={authenticateUsingToken}
                        triedToAuth={triedToAuth}
                    />
                    <ConditionalRoute authenticated={authenticated} exact path="/"/>
                    <Route path="/customer/access/:accessToken" exact component={CustomerAcceptAccessPage}/>
                    <Route path="/login" exact component={LoginPage}/>
                    <Route path="/packingList" exact component={PackList}/>
                    <Route component={NotFoundPage} />
                </Switch>
            </Router>
            {/*<LanguageSwitch/>*/}
        </div>
    }
}

const mapStateToProps = ({product, auth}) => {
    return {
        product,
        auth,
    }
}

export default connect(mapStateToProps, {authenticateUsingToken, redirectToLoginAction})(App)
