export default {
    "Buy Now": "Buy Now",
    "Samples Bazaar": "Samples Bazaar",
    "Cart": "Cart",
    "Logout": "Logout",
    "Wishlist": "Wishlist",
    "Available Now Collection & Offers": "Available Now Collection & Offers",
    "My Account – Information": "My Account – Information",
    "Registered Customers": "Registered Customers",
    "If you have an account": "If you have an account, sign in with your email address.",
    "Password": "Password",
    "Submit": "Submit",
    "Authentication Failed": "Authentication Failed",
    "Forgotten Password": "Forgotten Password",
    "Please enter your email address and click SEND": "Please enter your email address and click SEND. We’ll send you an email with the password.",
    "email address...": "email address...",
    "Send": "Send",
    "E-mail not Registered Please contact your agent": "E-mail not Registered Please contact your agent or send us an email at info@vamp.gr",
    "Thanks for submitting your email address": "Thanks for submitting your email address. We've sent you an email with the password. The email might take a couple of minutes to reach your account. Please check your junk mail to ensure you receive it.",
    "Contact Information": "Contact Information",
    "My Account": "My Account",
    "Customer": "Customer",
    "Customers": "Customers",
    "Customer Access": "Customer Access",
    "InformationStatistics": "Information & Statistics",
    "search...": "search...",
    "Select Customer": "Select Customer",
    "Create Customer": "Create Customer",
    "No customers found": "No customers found",
    "Minimum Order": "Minimum Order 10 pieces",
    "Page {number} of {total}": "Page {{number}} of {{total}}",
    "Size": "Size" ,
    "Price": "Price",
    "Add to Order": "Add to Order",
    "Remove From Order": "Remove From Order",
    "Quantity": "Quantity",
    "Total Price": "Total Price",
    "Checkout": "Checkout",
    "Are you sure about submitting your sample order?": "Are you sure about submitting your sample order?",
    "You have no items in your shopping cart": "You have no items in your shopping cart.",
    "Total Qty": "Total Qty",
    "Back": "Back",
    "Stock": "Stock",
    "size": "size",
    "color": "color",
    "Total": "Total",
    "Reset": "Reset",
    "Search in cart:": "Search in cart:",
    "Filters": "Filters",
    "Product": "Product",
    "Color": "Color",
    "Size / Quantity": "Size / Quantity",
    "edit": "edit",
    "Are you sure about deleting this product from cart?": "Are you sure about deleting this product from cart?",
    "Are you sure about deleting active cart?": "Are you sure about deleting active cart?",
    "Are you sure about saving this order for later use?": "Are you sure about saving this order for later use?",
    "Your order has been saved": "Your order has been saved",
    "All": "ALL",
    "Sort by": "Sort by",
    "Latest Season": "Latest Season",
    "Latest Offers": "Latest Offers",
    "Price Low to High": "Price Low to High",
    "Found itemnumber items": "Found: {{itemnumber}} items",
    "Row Count": "Row Count",
    "Are you sure about removing this item from your wishlist?": "Are you sure about removing this item from your wishlist?",
    "Art.:": "Art.:",
    "Show ALL": "Show ALL",
    "Families": "Families",
    "Catalogs": "Catalogs",
    "Top Articles ( Agent )": "Top Articles ( Agent )",
    "Top Articles": "Top Articles",
    "Personal Information": "Personal Information",
    "Billing Address": "Billing Address",
    "Shipping Address": "Shipping Address",
    "Season": "Season",
    "Delivery Date": "Delivery Date",
    "Assign order to other salesman": "Assign order to other salesman",
    "Notes": "Notes",
    "Order Review": "Order Review",
    "Total Quantity": "Total Quantity",
    "Save Order": "Save Order",
    "Complete Order": "Complete Order",
    "To change season, your cart has to be empty.": "To change season, your cart has to be empty.",
    "Please complete your order or save it for later.": "Please complete your order or save it for later.",
    "Place of order": "Place of order",
    "Please select an option": "Please select an option",
    "Logout Customer": "Logout Customer",
    "Hide Prices": "Hide Prices",
    "Set Password": "Set Password",
    "Change Customer Password": "Change Customer Password",
    "User Alt Code": "User Alt Code",
    "User VAT Number": "User VAT Number",
    "User Name": "User Name",
    "User email": "User email",
    "Salesmen": "Salesmen",
    "Account Information": "Account Information",
    "Please enter new user password": "Please enter new user password",
    "Password cannot be empty": "Password cannot be empty",
    "Password Updated": "Password Updated",
    "Customer Orders": "Customer Orders",
    "Seasons": "Seasons",
    "Select...": "Select...",
    "From Date": "From Date",
    "To Date": "To Date",
    "Current Year": "Current Year",
    "Current Month": "Current Month",
    "Previous Month": "Previous Month",
    "Today": "Today",
    "Search": "Search",
    "There is no orders with your filters": "There is no orders with your filters",
    "Go to page:": "Go to page:",
    "Show": "Show",
    "Order Number": "Order Number",
    "Order Date": "Order Date",
    "Order Type": "Order Type",
    "Account Balance:": "Account Balance:",
    "Delayed Balance:": "Delayed Balance:",
    "Delayed Days:": "Delayed Days:",
    "You have points!": "You have {{points}} points!",
    "Reedem at Checkout step": "Reedem at Checkout step",
    "You have collected {point} points": "You have collected {{point}} points",
    "Reedem them at Order Fields": "Reedem them at Order Fields",
    "You have {{point}} available points": "You have {{point}} available points",
    "Select points to redeem": "Select points to redeem",
    "10€ ( Redeem 10000 points )": "10€ ( Redeem 10000 points )",
    "20€ ( Redeem 50000 points )": "20€ ( Redeem 50000 points )",
    "50€ ( Redeem 50000 points )": "50€ ( Redeem 50000 points )",
    "Available Points": "Available Points",
    "Saved Orders": "Saved Orders",
    "You do not have any saved orders": "You do not have any saved orders",
    "Order ID": "Order ID",
    "Customer Name": "Customer Name",
    "Created": "Created",
    "Load": "Load",
    "Delete": "Delete",
    "Are you sure about deleting this order?": "Are you sure about deleting this order?",
    "Addresses": "Addresses",
    "Billing Information": "Billing Information",
    "Name": "Name",
    "Address": "Address",
    "City": "City",
    "Zip": "Zip",
    "Country": "Country",
    "Phone": "Phone",
    "Shipping Information": "Shipping Information",
    "The billing address will be used as shipping.": "The billing address will be used as shipping.",
    "There are not any additional shipping addresses.": "There are not any additional shipping addresses.",
    "Create new Address": "Create new Address",
    "Default Address": "Default Address",
    "District": "District",
    "Update": "Update",
    "Cancel": "Cancel",
    "Set Address as default": "Set Address as default",
    "Downloads": "Downloads",
    "Statements": "Statements",
    "Request": "Request",
    "Please select one of the following pages": "Please select one of the following pages",
    "Shipments": "Shipments",
    "View": "View",
    "Sent Quantity": "Sent Quantity",
    "Open Quantity": "Open Quantity",
    "Show 10": "Show 10",
    "Show 20": "Show 20",
    "Show 30": "Show 30",
    "Show 40": "Show 40",
    "Show 50": "Show 50",
    "Invoices & Credit Notes": "Invoices & Credit Notes",
    "Back to my Account": "Back to my Account",
    "Invoices": "Invoices",
    "Credit Notes": "Credit Notes",
    "Credits": "Credits",
    "Code": "Code",
    "Date": "Date",
    "Type": "Type",
    "Value": "Value",
    "Invoice Pdf": "Invoice Pdf",
    "Packing Pdf": "Packing Pdf",
    "Barcodes XLS": "Barcodes XLS",
    "Gender": "Gender",
    "Women": "Women",
    "Men": "Men",
    "Kids": "Kids",
    "Art Type": "Art Type",
    "Jackets": "Jackets",
    "Loungedresses": "Loungedresses",
    "Top": "Top",
    "Pyjamas": "Pyjamas",
    "Nightgowns": "Nightgowns",
    "Robes": "Robes",
    "Beachdresses": "Beachdresses",
    "Tracksuits": "Tracksuits",
    "Pants": "Pants",
    "Blouses": "Blouses",
    "Underwear": "Underwear",
    "Accessories": "Accessories",
    "Collection": "Collection",
    "Fabric": "Fabric",
    "Front": "Front",
    "Sleeve": "Sleeve",
    "Length": "Length",
    "Plus Size": "Plus Size",
    "Composition": "Composition",
    "Summer Winter": "Summer Winter",
    "Offer": "Offer",
    "Campaign": "Campaign",
    "For orders of total value under 400€ transport costs of 10€ may apply.":  "For orders of total value under 400€ transport costs of 10€ may apply.",
    "Place Order": "Place Order",
    "Thank you for your order!": "Thank you for your order!",
    "You can download a detailed report of your order by clicking the Pdf icon below": "You can download a detailed report of your order by clicking the Pdf icon below",
    "Order Summary": "Order Summary",
}