import React from 'react'
import './CampaignPage.scss'

const printCampaignBySeason = (seasonId) => {

    let count = 0;
    let hasVideo = true;

    switch (parseInt(seasonId)) {
        case 60: {
            count = 10
            break
        }
        case 62: {
            count = 24
            break
        }
        case 63: {
            hasVideo = false
            count = 31
            break
        }
        case 67: {
            hasVideo = false
            count = 27
            break
        }
        case 68: {
            hasVideo = true
            count = 29
            break
        }
        case 69: {
            hasVideo = false
            count = 25
            break
        }
        case 70: {
            hasVideo = false
            count = 25
            break
        }
        case 72: {
            hasVideo = false
            count = 25
            break
        }
    }

    let helperArray = [];

    for(let i = 1; i <= count; i++) {
        helperArray.push(i)
    }

    return <div className="campaign-page">
        {hasVideo ? <a className="video" data-fancybox="gallery"  href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/00.mp4`}>
            <video autoPlay={true} muted={true} controls={true} loop={true} src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/00.mp4`}></video>
        </a> : null}
        {helperArray.map(index => {

            let filename = ('0' + index).slice(-2)

            return  <a id={'link_' + index} data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/${filename}.jpg`}>
                <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/${filename}.jpg`}/>
            </a>
        })}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/01.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/01.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/02.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/02.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/03.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/03.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/04.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/04.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/05.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/05.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/06.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/06.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/07.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/07.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/08.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/08.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/09.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/09.jpg`}/>*/}
        {/*</a>*/}
        {/*<a data-fancybox="gallery" href={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/10.jpg`}>*/}
        {/*    <img alt="Vamp" src={`https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/campaign/${seasonId}/10.jpg`}/>*/}
        {/*</a>*/}
    </div>
}

const CampaignPage = ({ match }) => {

    if(!match || !match.params || !match.params.seasonId) {
        return null
    }

    const seasonId = match.params.seasonId

    return <div className="campaign-page">
        {printCampaignBySeason(seasonId)}
    </div>
}

export default CampaignPage

